import React from "react"
import { ServicePageLayout, layoutColorTypes } from "../../../layouts/service-page/service-page.layout"
import { getCanonicalUrl } from "../../../utils/getCanonicalUrl"

import { testimonials } from "./testimonials"
import { getStaticImgUrl } from "../../../utils/getStaticImgUrl"

const content = {
  title: "Blog Writing Services",
  whatWeOffer: {
    leftPart: "If you are looking for a content writing agency to manage your blog section, you have come to the right place. At Podroom Creative, we have a team of skilled, versatile bloggers who are trained to adjust to your specific brand voice.",
    rightPart: "We can take care of everything. The creative team will set up a content calendar, and our writers will prepare the content. Our experienced editors will make sure the blogs are post-ready, and our senior staff will schedule them for posting."
  },
  processText: "After you give us your seal of approval, you can rest assured knowing that your blog is in good hands. Contact us today!",
  testimonials: testimonials
}

const meta = {
  title: "Blog Writing Services",
  description: "If you are looking for a content writing agency to manage your blog section, you have come to the right place. At Podroom Creative, we have a team of skilled, versatile bloggers who are trained to adjust to your specific brand voice."
}

const og = {
  image: getStaticImgUrl("img_contnet_writing_full.jpg")
}

const pageSlug = "content-services/blog-writing";

export default () => (

  <ServicePageLayout 

    meta={meta}
    og={og}
    layoutColorType={layoutColorTypes.YELLOW}
    rightHeroImage={"/img_contnet_writing_full.jpg"}
    headerIcon={"/ico_content_writing_2@2x.png"}
    content={content}
    canonicalUrl={getCanonicalUrl(pageSlug)}

  />

)